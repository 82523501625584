import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Doodle #5",
  "featuredImage": "images/puppy.jpg",
  "coverAlt": "Doodle 5.",
  "description": "Doodle - National Puppy day",
  "date": "2018-03-15",
  "dateModified": "2018-03-15",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Doodles", "Graphic Design"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Doodle! Created this one for National Puppy day!`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1400px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7d4acf9ba6ec57e813e961bf7826eb3b/5814a/puppy.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAeLRJQjg1NIFhX//xAAaEAACAgMAAAAAAAAAAAAAAAABAwISEBEg/9oACAEBAAEFAgNymsrFsNZfn//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEv/aAAgBAwEBPwEl/wD/xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPwF6XP8A/8QAGRABAAIDAAAAAAAAAAAAAAAAAQARECAh/9oACAEBAAY/Agup1ya//8QAGhABAAIDAQAAAAAAAAAAAAAAAQAREDFBIf/aAAgBAQABPyEmKF3LWduggp2F28zd4//aAAwDAQACAAMAAAAQv9eC/8QAFxEBAAMAAAAAAAAAAAAAAAAAEQAQIf/aAAgBAwEBPxDAgv8A/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQAQEf/aAAgBAgEBPxCQwy//xAAfEAEAAgEDBQAAAAAAAAAAAAABABEhEDFBUWFxgaH/2gAIAQEAAT8QxRgaXXqI01DKs7vCdIC87twSi2l5qJsAnl+S9FblfOn/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Doodle 5",
              "title": "Doodle 5",
              "src": "/static/7d4acf9ba6ec57e813e961bf7826eb3b/5814a/puppy.jpg",
              "srcSet": ["/static/7d4acf9ba6ec57e813e961bf7826eb3b/543cd/puppy.jpg 360w", "/static/7d4acf9ba6ec57e813e961bf7826eb3b/20801/puppy.jpg 720w", "/static/7d4acf9ba6ec57e813e961bf7826eb3b/5814a/puppy.jpg 1400w"],
              "sizes": "(max-width: 1400px) 100vw, 1400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Doodle 5`}</figcaption>{`
  `}</figure></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      